import { FunctionComponent } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import styled from 'styled-components';

import { useDummyData } from '../../context/DummyDataProvider';
import { formatEntitlement, formatPrice, formatTimestamp, getOrderItemOptions } from '../../helpers/utils';
import { IOrder } from '../../types/IOrders';

import { OrderDetails } from '../OrderDetails/OrderDetails';
import { TextTag } from '../TextTag/TextTag';
import { ScheduleIndicator } from '../ScheduleIndicator/ScheduleIndicator';

import chemistworksLogo from '../../assets/images/logos/Chemistworks.png';
import catClaudiaLogo from '../../assets/images/logos/logo-catandclaudias.png';
import smithsLogo from '../../assets/images/logos/PlaceholderPharmacy.svg';
import southcityLogo from '../../assets/images/logos/logo-southcity.png';
import pricelineLogo from '../../assets/images/logos/logo-priceline-rounded.png';
import terryWhiteLogo from '../../assets/images/logos/TerryWhite_Chemmart_logo.png';
import placeholderImage from '../../assets/images/products/placeholder.png';
import baronePharmacyLogo from '../../assets/images/logos/BaronePharmacy.png';
import chemistDiscountCentreLogo from '../../assets/images/logos/ChemistDiscountCentre.png';


type PickListProps = {
  order: IOrder,
  partialRefundItems?: Array<any>
}

const getTotalPrice = (items: Array<any>): number => {
  return items.reduce((acc, item) => {
    const qty = item.qtyToRefund ? item.qty_ordered - item.qtyToRefund : item.qty_ordered;
    return acc + item.total * qty;
  }, 0);
}

const numberItems = (items: Array<any>): number => {
  return items.reduce((acc, item) => {
    return acc + (item.qtyToRefund ? item.qty_ordered - item.qtyToRefund : item.qty_ordered);
  }, 0);
}

export const PickList: FunctionComponent<PickListProps> = ({ order, partialRefundItems }) => {
  const items = partialRefundItems ? partialRefundItems : order.items;
  const totalPrice = getTotalPrice(items);

  const dummyData: any = useDummyData();

  const includeImage = false;

  // computed
  const partnerLogo = (): string => {
    if (dummyData.state.useDummyData) {
      return dummyData.state.dummyLogo;
    } else {
      switch (order.partner_id) {
        case 3:
          return chemistworksLogo;
        case 4:
          return catClaudiaLogo;
        case 5:
        case 6:
          return southcityLogo;
        case 7: // Baldwin Dev
        case 8: // Smith's Pharmacy
          return smithsLogo;
        case 11:
          return pricelineLogo;
        case 16:
          return terryWhiteLogo;
        case 18:
          return baronePharmacyLogo;
        case 19:
          return chemistDiscountCentreLogo;
        default:
          return '';
      }
    }
  }

  return (
    <StyledPickList>
      <img className="PickList_logo" src={partnerLogo()} alt="" />
      <div className="PickList_header flex">
        <h3>Pick List</h3>
        <OrderDetails order={order} />
      </div>
      <div className="PickList_summary flex">
        <p className="semibold">
          <span>You are picking {numberItems(items)} {numberItems(items) === 1 ? 'item' : 'items'}</span>
          <span style={{ margin: '0 12px' }}>|</span>
          <span>{formatPrice(totalPrice)}</span>
        </p>
        <p className="semibold">Order date: {formatTimestamp(order.created_at)}</p>
      </div>
      <div className="PickList_items">
        <div className={`PickList_itemRow header ${includeImage ? '' : 'noImage'}`}>
          <p className="bold" style={{ gridColumn: includeImage ? 'span 2' : undefined }}>Product Details</p>
          <p className="bold align_center">Qty</p>
          <p className="bold align_center">Picked?</p>
        </div>
        {items.map((item: any, i: number) => {
          let itemOptions = getOrderItemOptions(item);
          
          if (item.qty_ordered - item.qtyToRefund !== 0) {
            return (
              <div className={`PickList_itemRow ${includeImage ? '' : 'noImage'}`} key={`item-${i}-${item.sku}`}>
                {includeImage &&
                  <img src={item.imageSrc || placeholderImage} alt={item.name} />
                }
                <p>
                  <span className="sku">SKU: {item.sku}</span>
                  {item.gtin && <span className="sku">&nbsp;&nbsp;|&nbsp;&nbsp;Barcode: {item.gtin}</span> }
                  {item.pde_id && <span className="sku">&nbsp;&nbsp;|&nbsp;&nbsp;PDE: {item.pde_id}</span> }
                  <br />
                  {item.drug_schedule && <ScheduleIndicator schedule={item.drug_schedule} /> }
                  <span>{item.name}</span>
                  {itemOptions.map((option: any, optionIndex: number) => {
                    return (
                      <span className="options" key={optionIndex}>
                        <span className="bold">{option.option_label}: </span>
                        <span>{option.option_value}</span>
                      </span>
                    )
                  })}
                </p>
                <p className='bold align_center'>
                  <span>
                    {item.qtyToRefund === undefined
                      ? item.qty_ordered
                      : item.qty_ordered - item.qtyToRefund}
                  </span>
                  {item.requires_contact && <span className="qty_contact_text">Qty requires contact</span> }
                </p>
                <div className='checkbox'></div>
                {item.is_prescription && item.is_prescription === true ? (
                  <div className='PickList_prescriptionDetails'>
                    <div className='qrcode'>
                      <QRCodeSVG value={item.prescription_token_number || item.prescription_phone} size={60} />
                    </div>
                    <div className='tags'>
                      <TextTag text="Prescription Medication" />
                      <TextTag text={`Token: ${item.prescription_token_number || item.prescription_phone}`} />
                      <TextTag text={`Patient: ${item.prescription_first_name && item.prescription_last_name ? `${item.prescription_first_name} ${item.prescription_last_name}` : item.prescription_name}`} />
                      {(item.prescription_entitlement || item.prescription_concession) &&
                        <>
                          {item.prescription_entitlement_number ?
                            <TextTag text={`Entitlement: ${formatEntitlement(item.prescription_entitlement || item.prescription_concession)} (${item.prescription_entitlement_number})`} />
                            :
                            <TextTag text={`Entitlement: ${formatEntitlement(item.prescription_entitlement || item.prescription_concession)}`} />
                          }
                        </>
                      }
                    </div>
                  </div>
                ) : null}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </StyledPickList>
  )
}

const StyledPickList = styled.div`
  display: inline-block;
  width: 595px;
  height: 842px;
  padding: 60px;
  background: white;
  break-after: page;
  page-break-after: always;

  .PickList_logo {
    max-width: 150px;
    max-height: 75px;
  }

  .OrderDetails_id {
    font-size: 1rem !important; // 16px
    width: 100%;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .PickList_summary {
    background: #F8F8F8;
    padding: 0 16px;
    height: 44px;
    border-radius: 6px;

    p {
      font-size: 0.75rem; // 12px
    }
  }

  .PickList_items {
    margin-top: 30px;

    .PickList_itemRow {
      border-bottom: 1px solid #E7E7E7;

      display: grid;
      grid-template-columns: 50px 1fr 50px 50px;
      grid-gap: 0 31px;
      padding: 7px 4px;
      align-items: center;

      .options {
        display: inline-block;
        font-size: 0.75rem; // 12px
        margin: 0;
      }

      &.noImage {
        grid-template-columns: 1fr 50px 50px;

        .PickList_prescriptionDetails {
          grid-column: 1 / 2;
        }
      }

      img {
        width: 49px;
        height: 49px;
        object-fit: contain;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(180, 180, 180, 0.25);
        align-self: flex-start;
      }

      p {
        font-size: 0.75rem; // 12px
        margin: 9px 0;

        span.sku {
          font-size: 0.5rem; // 8px
        }
      }

      .checkbox {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        border: 1px solid #9B9B9B;
        margin: 0 auto;
      }

      .PickList_prescriptionDetails {
        grid-column: 2 / 4;
        display: flex;

        .qrcode {
          width: 60px;
          height: auto;
          margin-top: 8px;
        }

        .tags {
          margin-top: 7px;
          margin-left: 17px;

          span {
            font-size: 0.5rem; // 8px
            display: inline-block;
            margin-bottom: 6px;
            margin-right: 6px;
          }
        }
      }
    }
  }

  /* schedule indicator styling */
  .ScheduleIndicator {
    position: relative;
    left: unset;
    top: unset;
    display: inline-flex;
    margin: 2px 4px 2px 0 !important;
  }

  /* qty contact styling */
  .qty_contact_text {
    display: block;
    font-size: 0.535rem;
    margin-top: 5px;
  }
`